import { IAccession } from "./../interfaces/entity-interfaces";
import { DialogService } from "aurelia-dialog";
import { DataService } from "./../services/data-service";
import { autoinject, observable, TaskQueue } from "aurelia-framework";
import { FormattingService } from "services/formatting-service";
import { Router } from "aurelia-router";
import Chart from "chart.js/auto";
import annotationPlugin from "chartjs-plugin-annotation";

@autoinject
export class LabResult {
    private _dataService: DataService;
    private _dialogService: DialogService;
    private _formattingService: FormattingService;
    _taskQueue: TaskQueue;
    router: Router;

    isEditing: boolean = false;
    isLoadingLabResult: boolean = false;
    isLoadingPathologistComments: boolean = false;
    isLoadingLabResults: boolean = false;

    dbLabResult: IAccession;
    displayLabResult: IAccession;
    pathologistComments: any;
    labResults: any;

    labResultIsDownloadable: boolean = false;

    labResultsPromise: Promise<any>;

    @observable({ changeHandler: "searchUpdated" })
    patientSearchTerm: string = "";

    constructor(
        dataService: DataService,
        dialogService: DialogService,
        formattingService: FormattingService,
        taskQueue: TaskQueue
    ) {
        this._dataService = dataService;
        this._dialogService = dialogService;
        this._formattingService = formattingService;
        this._taskQueue = taskQueue;
    }

    async activate(params) {
        if (params.accessionId == null) return;

        this.isLoadingLabResult = true;
        this._dataService.getAccession(params.accessionId).then((accession) => {
            this.dbLabResult = accession;
            this.displayLabResult = accession;
            console.log("Display Result", this.displayLabResult);
            this.isLoadingLabResult = false;
        });

        this.isLoadingPathologistComments = true;
        this._dataService
            .getPathologistComments(params.accessionId)
            .then((pathologistComments) => {
                this.pathologistComments = pathologistComments;
                this.isLoadingPathologistComments = false;
            });

        this.isLoadingLabResults = true;
        this.labResultsPromise = this._dataService
            .getLabResults(params.accessionId)
            .then((labResults) => {
                this.labResults = labResults;
                this.labResults.testCategories.forEach((testCategory) => {
                    testCategory.labResults.forEach((labResult) => {
                        const random = Math.floor(Math.random() * 100000) + 1;
                        const stringRandom = random.toString();
                        labResult.stringRandom = stringRandom;
                        if (labResult.differentialTests.length > 0) {
                            labResult.differentialTests.forEach(
                                (differentialTest) => {
                                    const random =
                                        Math.floor(Math.random() * 100000) + 1;
                                    const stringRandom = random.toString();
                                    differentialTest.stringRandom =
                                        stringRandom;
                                }
                            );
                        }
                    });
                });
            });

        this.isLoadingLabResults = false;

        this._dataService
            .getPdfAvailability(params.accessionId)
            .then((result) => {
                console.log(result);
                this.labResultIsDownloadable = result.isPdfReportAvailable;
            });
    }

    attached() {
        this.labResultsPromise.then(() => {
            this.labResults.testCategories.forEach((testCategory) => {
                testCategory.labResults.forEach((labResult) => {
                    this.createResultChart(labResult);
                    if (labResult.differentialTests.length > 0) {
                        labResult.differentialTests.forEach(
                            (differentialTest) => {
                                this.createResultChart(differentialTest);
                            }
                        );
                    }
                });
            });
        });
    }

    getFullPatientSex(short) {
        switch (short) {
            case "M":
                return "Male";
            case "F":
                return "Female";
            default:
                "";
        }
    }
    getFullPatientNeutered(short) {
        switch (short) {
            case "Y":
                return "Neutered";
            default:
                return "Not Neutered";
        }
    }

    sanitizeName(name: string) {
        if (name == null) return;
        return this._formattingService.formatDisplayText(name);
    }

    edit() {
        this.isEditing = true;
    }
    save() {
        this.isEditing = false;
    }
    isResultInReferenceRange(range: string, result: string) {
        if (!range || !result) return true;
        var stringArray = range.split("-");
        stringArray.forEach((element) => {
            element.replace(/\s/g, "");
        });
        var lowerBound = parseFloat(stringArray[0]);
        var upperBound = parseFloat(stringArray[1]);
        var floatResult = parseFloat(result);

        if (floatResult < lowerBound || floatResult > upperBound) return false;

        return true;
    }

    resultsHaveDifferential(labResults: any[]) {
        labResults.forEach((result) => {
            if (result.resultType == "Differential") {
                return true;
            }
        });

        return false;
    }

    createResultChart(labResult: any) {
        const ctx = document.getElementById(
            "chart" + labResult.stringRandom
        ) as HTMLCanvasElement;

        Chart.register(annotationPlugin);

        if (labResult.referenceRange) {
            var stringArray = labResult.referenceRange.split("-");
            stringArray.forEach((element) => {
                element.replace(/\s/g, "");
            });
            var lowerBound = parseFloat(stringArray[0]);
            var upperBound = parseFloat(stringArray[1]);
            var floatResult = parseFloat(labResult.result);
            const chart = new Chart(ctx, {
                type: "bar",
                data: {
                    datasets: [
                        {
                            label: "",
                            backgroundColor: "#dc3545",
                            borderColor: "#dc3545",
                            data: [lowerBound], //this is lower bound - zero
                            barThickness: 6,
                            borderWidth: 0,
                        },
                        {
                            label: "",
                            backgroundColor: "#90e4f129",
                            borderColor: "#90e4f129",
                            data: [upperBound - lowerBound], // this is upper bound - lower bound
                            barThickness: 6,
                            borderWidth: 0,
                        },
                        {
                            label: "",
                            backgroundColor: "#dc3545",
                            borderColor: "#dc3545",
                            data: [this.getChartUpper(floatResult, upperBound)], //this is either
                            barThickness: 6,
                            borderWidth: 0,
                        },
                    ],
                    labels: [""],
                },
                options: {
                    // tooltips: {enabled: false},
                    hover: { mode: null },
                    plugins: {
                        annotation: {
                            annotations: {
                                annotation1: {
                                    type: "line",
                                    borderColor: `${
                                        this.isResultInReferenceRange(
                                            labResult.referenceRange,
                                            labResult.result
                                        )
                                            ? "black"
                                            : "#dc3545"
                                    }`,
                                    // borderDash: [3, 3],
                                    borderWidth: 1,
                                    xMax: floatResult, //this value says how far along the line it
                                    xMin: floatResult,
                                    xScaleID: "x",
                                    yMax: 0.2,
                                    yMin: -0.2,

                                    // yScaleID: "y",
                                },
                            },
                        },
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: false,
                        },
                    },
                    indexAxis: "y",
                    elements: {
                        bar: {
                            borderWidth: 1,
                        },
                    },
                    scales: {
                        x: {
                            // min: upperBound - lowerBound,
                            max:
                                this.getChartUpper(floatResult, upperBound) +
                                upperBound,
                            stacked: true,
                            grid: {
                                display: false,
                            },
                            border: {
                                display: false,
                            },
                            display: false,
                        },
                        y: {
                            stacked: true,
                            border: {
                                display: false,
                            },
                            grid: {
                                display: false,
                            },
                            display: false,
                        },
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                },
            });
        }
    }

    getChartUpper(result, upperBound) {
        if (result > upperBound) {
            return result - upperBound;
        }
        return upperBound * 0.2;
    }

    async downloadPdfReport() {
        const pdf = await this._dataService.downloadPdfReport(
            this.dbLabResult.accessionId
        );

        var url = window.URL.createObjectURL(pdf);

        // Create a link element to trigger the download
        var a = document.createElement("a");
        a.href = url;
        a.download = "downloaded.pdf"; // Set the desired file name
        document.body.appendChild(a);

        // Trigger a click event on the link element to initiate the download
        a.click();

        // Clean up by revoking the blob URL and removing the link element
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        // console.log("result", result);
    }
}
