import { DialogService } from "aurelia-dialog";
import { DataService } from "./../services/data-service";
import { autoinject, observable } from "aurelia-framework";
import { IPatient } from "interfaces/entity-interfaces";
import { FormattingService } from "services/formatting-service";
import { Router } from "aurelia-router";

@autoinject
export class Patients {
    private _dataService: DataService;
    private _dialogService: DialogService;
    private _formattingService: FormattingService;
    private router: Router;

    isLoadingPatients: boolean = false;

    displayedPatients: IPatient[];
    patients: IPatient[];

    @observable({ changeHandler: "searchUpdated" })
    patientSearchTerm: string = "";
    patientSearchTermLower: string = "";

    currentIndex: number = 0;
    takeQuantity: number = 20;

    nextButtonDisabled: boolean = false;

    constructor(
        dataService: DataService,
        dialogService: DialogService,
        formattingService: FormattingService,
        router: Router
    ) {
        this._dataService = dataService;
        this._dialogService = dialogService;
        this._formattingService = formattingService;
        this.router = router;
    }

    async activate() {
        await this.getPatients();
    }

    async getPatients() {
        this.isLoadingPatients = true;
        this.patients = await this._dataService.getPatients({
            searchTerm: "",
            currentIndex: this.currentIndex,
            takeQuantity: this.takeQuantity,
        });
        this.nextButtonDisabled = false;
        if (this.patients.length < 20) {
            this.nextButtonDisabled = true;
        }
        this.displayedPatients = this.patients;
        this.isLoadingPatients = false;
    }

    sanitizeName(name: string) {
        if (name == null) return;
        return this._formattingService.formatDisplayText(name);
    }

    navigateToPatient(patient: IPatient) {
        this.router.navigateToRoute("patient", {
            patientNumber: patient.patientNumber,
        });
    }

    searchUpdated() {
        if (this.patientSearchTerm == "") {
            this.displayedPatients = this.patients;
            return;
        }
        this.currentIndex = 0;

        this.patientSearchTermLower = this.patientSearchTerm.toLowerCase();
        this.getPatients();

        // const nameSearchPatients = this.patients.filter(
        //     (patient) =>
        //         (patient.patientFirstName &&
        //             patient.patientFirstName
        //                 .toLowerCase()
        //                 .includes(patientSearchTermLower)) ||
        //         (patient.patientLastName &&
        //             patient.patientLastName
        //                 .toLowerCase()
        //                 .includes(patientSearchTermLower))
        // );

        // this.displayedPatients = Array.from(
        //     new Set([
        //         ...nameSearchPatients,
        //         // ...emailSearchPatients,
        //         // ...numberSearchPatients,
        //     ])
        // );
        // console.log("displayedPatients", this.displayedPatients);
    }
    nextTablePage() {
        this.currentIndex += 1;
        this.getPatients();
    }
    previousTablePage() {
        if (this.currentIndex == 0) return;
        this.currentIndex -= 1;
        this.getPatients();
    }
}
