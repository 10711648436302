import { FormattingService } from "./../services/formatting-service";
import { DialogService } from "aurelia-dialog";
import { DataService } from "./../services/data-service";
import { autoinject, observable } from "aurelia-framework";
import { IAccession } from "interfaces/entity-interfaces";
import { Router } from "aurelia-router";

@autoinject
export class LabResults {
    private _dataService: DataService;
    private _dialogService: DialogService;
    private router: Router;
    private _formattingService: FormattingService;

    displayedLabResults: any[];
    isLoadingLabResults: boolean = false;
    labResults: any[];

    @observable({ changeHandler: "searchUpdated" })
    labResultsSearchTerm: string = "";

    constructor(
        dataService: DataService,
        dialogService: DialogService,
        router: Router,
        formattingService: FormattingService
    ) {
        this._dataService = dataService;
        this._dialogService = dialogService;
        this.router = router;
        this._formattingService = formattingService;
    }

    async activate() {
        this.getLabResults();
    }
    attached() {}

    async getLabResults() {
        this.isLoadingLabResults = true;
        this.labResults = await this._dataService.getAccessions(null);
        this.displayedLabResults = this.labResults;
        this.isLoadingLabResults = false;
        console.log(this.displayedLabResults);
    }

    navigateToLabResult(labResult: IAccession) {
        this.router.navigateToRoute("lab-result", {
            accessionId: labResult.accessionId,
        });
    }

    searchUpdated() {
        if (this.labResultsSearchTerm == "") {
            this.displayedLabResults = this.labResults;
            return;
        }

        const resultSearchTermLower = this.labResultsSearchTerm.toLowerCase();

        const nameSearchPatients = this.labResults.filter(
            (patient) =>
                (patient.patientFirstName &&
                    patient.patientFirstName
                        .toLowerCase()
                        .includes(resultSearchTermLower)) ||
                (patient.patientLastName &&
                    patient.patientLastName
                        .toLowerCase()
                        .includes(resultSearchTermLower))
        );
        const accessionSearchResults = this.labResults.filter(
            (result) =>
                result.accessionId &&
                result.accessionId.toLowerCase().includes(resultSearchTermLower)
        );

        this.displayedLabResults = Array.from(
            new Set([
                ...nameSearchPatients,
                ...accessionSearchResults,
                // ...numberSearchPatients,
            ])
        );
    }
    sanitizeName(name: string) {
        if (name == null) return;
        return this._formattingService.formatDisplayText(name);
    }
    getStatusColor(status: string) {
        switch (status) {
            case "Tests Complete":
                return "success";
            case "Test In Progress":
                return "primary";
            default:
                return "info";
        }
    }
}
