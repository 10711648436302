import {
    IAccession,
    IBreed,
    ISpecies,
} from "./../interfaces/entity-interfaces";
import { DialogService } from "aurelia-dialog";
import { DataService } from "./../services/data-service";
import { autoinject, observable, bindable } from "aurelia-framework";
import { IPatient } from "interfaces/entity-interfaces";
import { FormattingService } from "services/formatting-service";
import { Router } from "aurelia-router";

@autoinject
export class Patient {
    private _dataService: DataService;
    private _dialogService: DialogService;
    private _formattingService: FormattingService;
    router: Router;

    isEditing: boolean = false;
    isLoadingPatient: boolean = false;

    dbPatient: IPatient;
    displayPatient: IPatient;
    patientRequisitions: IAccession[];

    speciesAndBreeds: any;
    species: ISpecies[];
    breeds: IBreed[];

    selectedSpecies: ISpecies;
    patientSex: any;

    availableSexes = [
        { id: "FS", description: "Female - Spayed" },
        { id: "MN", description: "Male - Neutered" },
        { id: "F", description: "Female - Unspayed" },
        { id: "M", description: "Male - Unneutered" },
    ];

    @observable({ changeHandler: "searchUpdated" })
    patientSearchTerm: string = "";

    constructor(
        dataService: DataService,
        dialogService: DialogService,
        formattingService: FormattingService,
        router: Router
    ) {
        this._dataService = dataService;
        this._dialogService = dialogService;
        this._formattingService = formattingService;
        this.router = router;
    }

    async activate(params) {
        if (params.patientNumber == null) return;

        this.isLoadingPatient = true;
        this.dbPatient = await this._dataService.getPatient(
            params.patientNumber
        );
        this.displayPatient = this.dbPatient;
        console.log("Display Patient", this.displayPatient);

        const speciesAndBreeds =
            await this._dataService.getSpeciesAndBreedsDataFromServerAsync(
                null
            );

        this.speciesAndBreeds = speciesAndBreeds;
        console.log("Species + Breeds", speciesAndBreeds);

        const species = speciesAndBreeds.species.map((s) => {
            return {
                id: s.id,
                descr: this._formattingService.formatDisplayText(s.descr),
                short_descr: s.short_descr,
            };
        });
        this.species = species;
        const breeds = speciesAndBreeds.breeds;
        this.breeds = breeds;

        this.selectedSpecies = this.speciesAndBreeds.species.find(
            (s) => s.id == this.displayPatient.species
        );

        this.patientRequisitions = await this._dataService.getLabRequsitions({
            patientId: this.displayPatient.patientNumber,
        });
        console.log("Patient Requisitions", this.patientRequisitions);

        this.isLoadingPatient = false;
    }

    async attached() {
        const speciesAndBreeds =
            await this._dataService.getSpeciesAndBreedsDataFromServerAsync(
                null
            );

        this.speciesAndBreeds = speciesAndBreeds;

        const species = speciesAndBreeds.species.map((s) => {
            return {
                id: s.id,
                descr: this._formattingService.formatDisplayText(s.descr),
                short_descr: s.short_descr,
            };
        });
        const breeds = speciesAndBreeds.breeds;
    }

    sanitizeName(name: string) {
        if (name == null) return;
        return this._formattingService.formatDisplayText(name);
    }

    edit() {
        this.isEditing = true;
    }
    save() {
        this.isEditing = false;
    }
    cancel() {
        this.isEditing = false;
    }

    navigateToLabResult(labResult: IAccession) {
        this.router.navigateToRoute("lab-result", {
            accessionId: labResult.accessionId,
        });
    }

    getStatusColor(status: string) {
        switch (status) {
            case "Tests Complete":
                return "success";
            case "Test In Progress":
                return "primary";
            default:
                return "info";
        }
    }
}
