import { IAccession } from "interfaces/entity-interfaces";
import * as moment from "moment";

export class LabRequisitions {

    labRequisitions: Array<IAccession>;

    constructor() { }

    async activate() {

        const returnedData = await fetch("/labRequisitions", {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                //'Authorization': `Bearer ${this._authenticationService.session.token}`

            }
        });

        this.labRequisitions = await returnedData.json();

        console.log(this.labRequisitions);

    }
    attached() { }
}